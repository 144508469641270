import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { Message } from '@arco-design/web-vue';

import ArcoVue from '@arco-design/web-vue'
import '@arco-design/web-vue/dist/arco.css'

import qs from 'qs'; // 导入 qs 模块
import axios from 'axios'

import store from './store/store.js';

const app = createApp(App)

// import { createPinia } from 'pinia';
// const pinia = createPinia();
// app.use(pinia)

import TitleLine from './components/common/TitleLine.vue';
app.component('TitleLine', TitleLine);

import Noisy from './components/common/Noisy.vue';
app.component('Noisy', Noisy);

// 将 qs 添加到 Vue 的原型中
app.config.globalProperties.$qs = qs;

// 全局配置 Axios，例如设置基础URL等
if (process.env.NODE_ENV === 'development') {
  // 开发环境下的操作
  // axios.defaults.baseURL = 'http://localhost:8898';
  // app.config.globalProperties.baseURL='http://localhost:8898'

} else if (process.env.NODE_ENV === 'production') {
  // 生产环境下的操作
  // axios.defaults.baseURL = 'https://adminapi2024.9qe.com';
  // app.config.globalProperties.baseURL='https://adminapi2024.9qe.com'
}

axios.interceptors.request.use(config => {
  // const token = localStorage.getItem('token');
  // if (token) {
  //   config.headers.authorization = `Bearer ${token}`;
  // }
  // return config;
});

axios.interceptors.response.use(
  (response) => {
    // 对响应数据做些什么
    return response;
  },
  (error) => {
    console.log(error.response)
    if(!error.response||!error.response.status||error.response.status==401){
      // router.push('/login');
    }
    
    
    
  }
);

// 将 Axios 添加到 Vue 的原型中，以便在组件中通过 this.$axios 访问
app.config.globalProperties.$axios = axios;

// import { createDeviceDetector } from "next-vue-device-detector";
// export const device = createDeviceDetector()

Message._context = app._context;
import vuetyped from 'vue3typed'

import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'


const downResume=()=> {
  const url = 'https://img.dbnlab.com/junhao/job/马俊豪_Miko_简历.pdf';
  const link = document.createElement('a');
  link.href = url;
  link.download = '马俊豪_Miko_简历.pdf'; // 设置下载文件名
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

app.config.globalProperties.$downResume = downResume;

app
  .use(ArcoVue)
  .use(router)
  .use(vuetyped)
  .use(VueVideoPlayer)
  .use(store)
  // .use(device)
  .mount('#app')

export { axios };